import React, { useEffect, useRef } from "react";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const technologies = [
  { name: "HTML", src: "/assets/icons/html.png" },
  { name: "CSS", src: "/assets/icons/css.png" },
  { name: "JavaScript", src: "/assets/icons/javascript.png" },
  { name: "React", src: "/assets/icons/react.png" },
  { name: "TypeScript", src: "/assets/icons/typescript.png" },
  { name: "TailwindCSS", src: "/assets/icons/tailwind.png" },
  { name: "PHP", src: "/assets/icons/php.png" },
  { name: "NodeJS", src: "/assets/icons/nodejs.png" },
  { name: "Wordpress", src: "/assets/icons/wordpress.png" },
  { name: "Csharp", src: "/assets/icons/csharp.png" },
  { name: "C", src: "/assets/icons/c.png" },
  { name: "Python", src: "/assets/icons/python.png" },
  { name: "Git", src: "/assets/icons/git.png" },
  { name: "SQLite", src: "/assets/icons/sqlite.png" },
  { name: "Docker", src: "/assets/icons/docker.png" },
  { name: "Nginx", src: "/assets/icons/nginx.png" },
  { name: "Wedos", src: "/assets/icons/wedos.png" },
  { name: "Cloudflare", src: "/assets/icons/cloudflare.png" },
  { name: "Netlify", src: "/assets/icons/netlify.png" },
  { name: "Contabo", src: "/assets/icons/contabo.png" },
  { name: "Linux", src: "/assets/icons/linux.png" },
  { name: "Minecraft", src: "/assets/icons/minecraft.png" },
];

const App: React.FC = () => {
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const duplicateItems = () => {
      const sliderWidth = slider.scrollWidth;
      const parentWidth = slider.parentElement!.offsetWidth;

      if (sliderWidth < parentWidth * 2) {
        slider.innerHTML += slider.innerHTML;
        duplicateItems();
      }
    };

    duplicateItems();
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white font-sans flex flex-col justify-between">
      {/* Header */}
      <header className="text-center py-6 md:py-10 md:mt-4 mt-10">
        <h1 className="text-3xl md:text-4xl font-bold mb-4">ONDŘEJ PACOVSKÝ</h1>
        <p className="text-gray-300 max-w-4xl mx-auto text-base md:text-lg">
        Jmenuji se Ondřej Pacovský, je mi 18 let a studuji na SŠ a VOŠ Aplikované Kybernetiky v Hradci Králové. Již od malička jsou moji zálibou technologie, vždy jsem se jim s radostí věnoval a učil se nové věci.<br></br><br></br>

Od roku 2019 vytvářím statické webové stránky. Na střední škole jsem si oblíbil síťařinu a programování mikroprocesorů, což mi otevřelo dveře k vývoji desktopových a serverových aplikací, UI/UX, grafice a dalším oblastem. Mé zkušenosti a certifikace lze nalést na mém <a target="_blank" href="https://www.linkedin.com/in/ondrejpacovsky/">LinkedInu</a>. <br></br><br></br>

Baví mě pracovat na nových projektech a určitě se ničemu nebráním. Spolupracoval jsem se spousty menších, ale i větších herních serverů, na kterých jsem nabral zkušenosti s vedením a prací v týmu. Ve volném čase vypomáhám s projektem LearnHTML, který se zaměřuje na pomoc s technickými problémy v oblasti programování.
        </p>
      </header>

      {/* Slider */}
      <section className="text-center py-4 md:py-6">
        <div className="slider-container mx-auto overflow-hidden">
          <div ref={sliderRef} className="slider-track">
            {technologies.map((tech, index) => (
              <div
                key={index}
                className="slider-item flex flex-col items-center mx-4"
              >
                <img
                  src={tech.src}
                  alt={tech.name}
                  className="w-12 h-12 md:w-14 md:h-14 object-contain"
                />
                <p className="mt-2 text-xs md:text-sm text-gray-300">
                  {tech.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="flex flex-wrap justify-center gap-4 px-4 py-4 md:py-6">
        {/* Links */}
        <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md w-full sm:w-72">
          <h3 className="text-xl font-semibold mb-4">Odkazy</h3>
          <ul className="text-gray-300 space-y-3">
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/ondrejpacovsky/"
                className="flex items-center gap-2 text-blue-400 hover:underline"
              >
                <i className="fa-brands fa-linkedin"></i> Linkedin
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://instagram.com/ondrasekq_"
                className="flex items-center gap-2 text-blue-400 hover:underline"
              >
                <i className="fa-brands fa-instagram"></i> Instagram
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://blog.ondrejpacovsky.cz/moje-technika"
                className="flex items-center gap-2 text-blue-400 hover:underline"
              >
                  <i className="fa-solid fa-toolbox"></i> Technika
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://blog.ondrejpacovsky.cz/"
                className="flex items-center gap-2 text-blue-400 hover:underline"
              >
                <i className="fa-solid fa-blog"></i> Blog
              </a>
            </li>
          </ul>
        </div>

        {/* Contact */}
        <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md w-full sm:w-72">
          <h3 className="text-xl font-semibold mb-4">Kontakt</h3>
          <ul className="text-gray-300 space-y-3">
            <li>
              <i className="fas fa-envelope"></i> info@ondrejpacovsky.cz
            </li>
            <li>
              <i className="fab fa-discord"></i> ondra907
            </li>
          </ul>
        </div>

        {/* Support */}
        <div className="flex flex-col items-center bg-gray-800 rounded-lg p-6 shadow-md w-full sm:w-72">
          <h3 className="text-xl font-semibold mb-4">Podpoř mě</h3>
          <ul className="text-gray-300 space-y-3">
          <li><i className="fa-solid fa-building-columns mr-2"></i>670100-2215776729/6210</li>
          <li><i className="fa-solid fa-building-columns mr-2"></i>CZ2862106701002215776729</li>
          <li><i className="fa-brands fa-paypal"></i> <a target="_blank" href="https://www.paypal.com/paypalme/ondra907">paypal.me/ondra907</a></li>
          <li><i className="fa fa-right-from-bracket"></i> <a target="_blank" href="https://blog.ondrejpacovsky.cz/podpor-me/">Zobrazit vše</a></li>
          </ul>
        </div>
      </section>

      {/* Footer */}
      <footer className="text-center py-4 text-gray-500">
        <p>© 2023-{(new Date().getFullYear())} Ondřej Pacovský. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;